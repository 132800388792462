.custom-carousel {
  position: relative;
  .carousel-control-prev,
  .carousel-control-next,
  .carousel-indicators {
    display: none;
  }
  .carousel-item {
    height: 100vh;
  }
  video {
    min-width: 100%;
    min-height: 100vh;
  }
  &__caption {
    position: absolute;
    bottom: 40px;
    color: #ffffff;
    line-height: normal;
    h3 {
      font-size: 34px;
      text-shadow: 1px 2px 3px #0000003b;
    }
    h2 {
      font-size: 40px;
      font-weight: 600;
      border-bottom: 1px solid #ffffff;
      text-shadow: 1px 2px 3px #0000003b;
    }
    img {
      margin: 18px 20px 0 0;
    }
  }
  .testing {
    position: absolute;
    top: 0;
    font-size: 30px;
    z-index: 9;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
.about-cuspyd {
  margin-top: -7px;
  p {
    font-size: 18px;
    font-weight: 600;
  }
}
.spotlights {
  h1 {
    color: #21ade430;
    font-size: 100px;
    font-weight: 600;
    line-height: normal;
  }
  h5 {
    font-size: 36px;
    font-weight: 600;
    line-height: normal;
    margin-top: -50px;
  }
  p {
    font-size: 18px;
    strong {
      font-weight: 600;
    }
  }
}
.our-plans {
  &__card {
    line-height: normal;
    transition: all ease 0.5s;
    strong {
      font-size: 36px;
      font-weight: normal;
    }
    span {
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      small {
        text-decoration: none;
        font-size: 16px;
      }
    }
    small {
      font-size: 22px;
      text-decoration: line-through;
      text-transform: none;
    }
    .list {
      min-height: 400px;
      .item {
        em {
          font-style: normal;
          display: block;
          margin-bottom: 20px;
          font-size: 18px;
          i {
            background-color: aliceblue;
            border-radius: 100%;
            width: 26px;
            height: 26px;
            display: inline-block;
            text-align: center;
            font-size: 20px;
            line-height: 28px;
            margin-right: 4px;
            vertical-align: sub;
            &.bi-x-lg {
              font-size: 16px;
              color: #f7aaa1;
            }
          }
        }
      }
    }
    &:hover {
      border: 1px solid #21ade4;
      .btn-primary {
        background-color: #21ade4;
        color: #ffffff;
      }
      .info-icon  {
        background-color: transparent;
        color: #21ade4;
      }
    }
    .btn-primary {
      border-radius: 0;
      border: 1px solid #21ade4;
      background-color: transparent;
      color: #000000;
      font-weight: 600;
      font-size: 16px;
      height: 50px;
    }
    .info-icon {
      padding: 0;
      border: 0;
      height: 20px;
      width: 20px;
      vertical-align: baseline;
    }
  }
}
.courses {
  &__card {
    min-height: 500px;
    transition: all ease 0.5s;
    position: relative;
    strong {
      font-size: 24px;
    }
    p {
      font-size: 16px;
      strong {
        font-size: 16px;
      }
    }
    &:hover {
      border-bottom: 4px solid #c9e7fa;
    }
    .badge {
      position: absolute;
      top: 12px;
      right: 10px;
      background: rgb(0 0 0 / 51%);
    }
    img {
      max-height: 200px;
      width: 100%;
    }
  }
}
.getin-touch {
  .form-control,
  .form-select {
    border-radius: 0;
    font-size: 14px;
    min-height: 50px;
  }
  img {
    max-width: 100%;
  }
}

.custom-model {
  .modal-body {
    h6 {
      color: #21ade4;
      font-weight: 600;
      font-size: 22px;
      small {
        text-decoration: line-through;
      }
    }
    strong {
      color: #21ade4;
      font-weight: normal;
      font-size: 18px;
    }
    li {
      strong {
        color: #000000;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .custom-carousel {
    video {
      min-width: 100%;
      max-width: 100%;
      min-height: 100%;
      object-fit: cover;
    }
    .carousel-item {
      height: 30vh;
    }
    &__caption {
      img {
        margin: 0px 20px 0 0;
        height: 20px;
      }
      h2 {
        font-size: 18px;
      }
      h3 {
        font-size: 16px;
      }
    }
  }
  .about-cuspyd {
    img {
      height: 24px;
    }
    p {
      font-size: 12px;
      font-weight: 600;
      margin: 14px 0 0;
      }
  }
  .section-title {
    strong {
      font-size: 22px;
    }
    span {
      font-size: 14px;
    }
  }
  .our-plans {
    &__card {
      .list {
        min-height: inherit;
      }
    }
  }
  .spotlights {
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
