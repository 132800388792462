.custom-header {
  .fixed-top {
    padding: 30px 0;
    .dark-logo {
      display: none;
    }
    &.bg-dark {
      background-color: #ffffff !important;
      transition: all ease 0.9s;
      transition: transform 0.5s;
      transform: translateY(100px);
      top: -100px;
      box-shadow: 0px -1px 5px 1px rgb(0 0 0 / 12%);
      padding: 10px 0;
      .navbar-nav {
        a {
          color: #000000;
          text-shadow: none;
          cursor: pointer;
          &:last-child {
            color: #ffffff;
            cursor: pointer;
          }
        }
      }
      .dark-logo {
        display: inline-block;
      }
      .light-logo {
        display: none;
      }
    }
  }
  .navbar-nav {
    a {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600;
      text-shadow: 1px 2px 3px #0000003b;
      color: #ffffff;
      margin-left: 10px;
      &:last-child {
        background-color: #000000;
        padding: 8px 20px;
        border-radius: 60px;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}
.tagline {
  font-size: 14px;
  color: #2a3881;
  font-weight: 600;
  text-transform: uppercase;
}
.nav-link {
  cursor: pointer;
}
.light-blue-bg {
  background-color: #e9f7fc;
}
.gray-bg {
  background-color: #f9f9f9;
}
.white-bg {
  background-color: #ffffff;
}
.bg1 {
  background-color: #baeafa;
}
.bg2 {
  background-color: #c9e7fa;
}
.bg3 {
  background-color: #f6ecf8;
}
.btn-arrow {
  border-radius: 60px;
  padding: 6px 6px 4px 20px;
  background-color: #21ade4;
  border: 0;
  font-size: 14px;
  font-weight: normal;
  background-image: -moz-linear-gradient(top, #21ade4 0%, #21ade4 100%);
  background-image: -webkit-linear-gradient(top, #21ade4 0%, #21ade4 100%);
  background-image: linear-gradient(to bottom, #21ade4 0%, #21ade4 100%);
  background-size: 300px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
  span {
    width: 30px;
    height: 30px;
    font-size: 18px;
    text-align: center;
    padding: 0;
    line-height: 32px;
    vertical-align: middle;
    border-radius: 50%;
    background-color: #ffffff !important;
    color: #21ade4;
  }
  &:hover {
    background-color: #2a3881;
    background-position: -200%;
    transition: background 900ms ease-in-out;
  }
}
.section-title {
  line-height: normal;
  font-weight: 600;
  span {
    font-size: 18px;
    color: #21ade4;
    display: block;
  }
  strong {
    font-size: 32px;
    display: inline-block;
    position: relative;
    small {
      width: 60px;
      height: 2px;
      background-color: #21ade4;
      display: inline-block;
    }
  }
}

.navbar {
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.btn-primary-1 {
  min-width: 200px;
  border-radius: 0;
  border: 1px solid #21ade4;
  background-color: #21ade4;
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  height: 50px;
  color: #ffffff;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }
}
.custom-fotter {
  background-color: #6ec4e6;
  .nav {
    display: inline-block;
  }
  li {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 16px;
    a {
      text-decoration: none;
      color: #ffffff;
    }
  }
  a {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
    color: #ffffff;
    padding: 0;
  }
}
.alpha-list {
  list-style-type: lower-alpha;
}
.sub-content {
  h2 {
    font-size: 22px;
    font-weight: 600;
    margin-top: 40px;
    color: #21ade4;
    &:first-child {
      margin-top: 0;
    }
  }
  h3 {
    font-size: 16px;
    font-weight: 600;
    position: relative;
    margin-top: 20px;
    color: #2a3881;
    // &::before {
    //   position: absolute;
    //   content: "";
    //   width: 6px;
    //   height: 6px;
    //   background-color: #000000;
    //   left: 0;
    //   top: 7px;
    //   border-radius: 50%;
    // }
  }
  h4, h5 {
    font-size: 14px;
    font-weight: 600;
  }
  p {
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
  }
  li {
    font-size: 14px;
  }
}

.blog-sub-page {
  .section-title {
    .btn-arrow {
      background: transparent;
      font-size: 30px;
      padding: 0;
      i {
        font-size: 42px;
        vertical-align: middle;
      }
    }
  }
  .inner-page__banner {
    min-height: inherit;
    height: 400px;
  }
}
.only-mobile {
  display: none;
}
.our-story-img {
  min-width: 100%;
  max-width: 100%;
}
.our-logo {
  max-width: 40%;
}
@media screen and (max-width: 768px) {
  .navbar-toggler {
    background-color: #ffffff;
  }
  .navbar-nav {
    background-color: #ffffff;
    padding: 10px 10px 30px;
  }
  .custom-header {
    .navbar-nav {
      a {
        color: #000000;
      }
    }
  }
  [data-aos] {
    opacity: 1 !important;
    transform: none !important;
    transition: none !important;
  }
  .section-title {
    span {
      font-size: 14px;
    }
    strong {
      font-size: 22px;
    }
  }
  .spotlights {
    h1 {
      font-size: 55px;
    }
    h5 {
      font-size: 22px;
      margin-top: -30px;
    }
    p {
      font-size: 12px;
    }
  }
  .only-mobile {
    display: block;
    .carousel-indicators {
      display: none;
    }
    .carousel-control-prev {
      background: url(../images/prev-slide.svg) no-repeat center;
      opacity: inherit;
      width: inherit;
      span {
        color: red;
      }
    }
    .carousel-control-next-icon {
      background-image: none;
    }
    .carousel-control-next {
      background: url(../images/next-slide.svg) no-repeat center;
      opacity: inherit;
      width: inherit;
      span {
        color: red;
      }
    }
    .carousel-control-prev-icon {
      background-image: none;
    }
  }
  .only-desktop {
    display: none;
  }
}
