.inner-page {
  &__banner {
    width: 100%;
    max-height: 100vh;
  }
  .section-title {
    margin-top: -180px;
    color: #ffffff;
    small {
      background-color: #ffffff;
    }
  }
}
.gradiant-bg {
  background: #d6e5ff;
  background: radial-gradient(circle, #d6e5ff 0%, #d6f6ff 100%);
  min-height: 1000px;
  border-radius: 20px;
  position: relative;
  top: -100px;
}
h5 {
  font-size: 28px;
  font-weight: 600;
  line-height: normal;
}
.acr_01 {
  background-color: #baeafa;
}
.accordion-item {
  border-radius: 8px;
  margin: 10px 0;
  button {
    &:focus {
      box-shadow: none;
    }
  }
}
.blog-post {
  position: relative;
  cursor: pointer;
  min-height: 300px;
  max-height: 300px;
  img {
    width: 100%;
    max-height: 300px;
  }
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgb(0 0 0 / 30%);
    z-index: 2;
    left: 0;
    border-radius: 15px;
  }
  .description {
    position: absolute;
    bottom: 10px;
    padding: 10px 25px;
    z-index: 4;
    color: #fff;
    span {
      font-size: 17px;
      display: block;
      font-weight: 600;
    }
    small {
      text-transform: uppercase;
      display: block;
      margin-top: 10px;
      font-weight: 600;
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 768px) {
  .gradiant-bg {
    top: 12px;
    margin-bottom: 23px;
  }
  .inner-page {
    .section-title {
      margin-top: -50px;
      strong {
        font-size: 18px;
      }
    }
  }
  .inner-body {
    img {
      max-width: 100%;
      max-height: 100%;
      height: auto;
    }
  }
  .blog-post {
    margin: 10px 0;
  }
}
